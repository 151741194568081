/* Link typekit fonts. Needed for Proxima Nova: */
@import url("https://use.typekit.net/urv3joi.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.backgroundImage {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='467' height='444' fill='none'%3E%3Cpath fill='%23F1F1F4' d='M233.178 0 .147 444h98.256l134.775-270.038L367.953 444h98.284L233.178 0Z'/%3E%3C/svg%3E");
  background-position-y: 40%;
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: auto;
}
